<template>
  <LoadingState v-if="isLoading" />
  <div v-else class="reportCenter">
    <!-- <a-modal
      :visible="modalConfirmManageKd"
      :confirm-loading="confirmLoading"
      @ok="handleOkConfirm"
      @cancel="modalConfirmManageKd = false"
      :maskStyle="{
        opacity: 0.5,
        backgroundColor: 'black',
      }"
      okText="Confirm"
      :okButtonProps="{
        props: {
          shape: 'round',
        },
      }"
      :cancelButtonProps="{
        props: {
          shape: 'round',
        },
      }"
    >
      <h3 slot="title" style="color: #faad14">
        <a-icon style="font-size: 1.2em" type="warning" /> UPDATE KD
        CONFIRMATION
      </h3>
      <p>
        Are you sure to update KD on level {{ overviewReport.tingkat }} subject
        {{ overviewReport.mapel }}? Your data before will be lost once you
        update.
      </p>
    </a-modal>
    <a-modal
      :visible="modalManageKdIsOpen"
      :confirm-loading="confirmLoading"
      @ok="handleOkManage"
      @cancel="handleCancelManage"
      :maskStyle="{
        opacity: 0.5,
        backgroundColor: 'black',
      }"
      okText="Update"
      :okButtonProps="{
        props: {
          shape: 'round',
        },
      }"
      :cancelButtonProps="{
        props: {
          shape: 'round',
        },
      }"
    >
      <h3 class="mb-3">Manage KD Amount</h3>
      <a-form layout="horizontal">
        <a-form-item label="Total KD per semester (Max. 12 KD)">
          <a-input-number
            :min="0"
            :max="12"
            style="width: 100%"
            v-model="kdAmount.total"
            @change="changeKdNumber"
          />
        </a-form-item>
        <a-form-item label="KD amount before mid report">
          <a-input-number
            :min="0"
            :max="kdAmount.total"
            style="width: 100%"
            v-model="kdAmount.mid"
            @change="changeKdNumber"
          />
        </a-form-item>
        <a-form-item label="KD amount after mid report">
          <a-input-number
            :min="0"
            style="width: 100%"
            v-model="kdAmount.final"
            disabled
          />
        </a-form-item>
      </a-form>
    </a-modal> -->
    <a-row>
      <a-col :span="24">
        <h1>Report Center</h1>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24">
        <div class="card-slots">
          <a-menu v-model="current" mode="horizontal">
            <a-menu-item
              v-for="(slot, index) in menuSlots"
              :key="index"
              @click="
                showOverview(slot.id_mata_pelajaran, slot['kela.level.id'])
              "
              >{{ slot["kela.level.nama"] }} |
              {{ slot["mata_pelajaran.nama"].toUpperCase() }}</a-menu-item
            >
          </a-menu>
          <LoadingState v-if="loadingOverview" />
          <div class="detail-slot mt-4" v-else>
            <a-row>
              <a-col :xs="12" :lg="5">
                <h3 class="type">Level</h3>
                <p class="value">
                  {{
                    overviewReport.level
                      ? `${overviewReport.level.nama} (${overviewReport.level.kelas.length} Classes)`
                      : "-"
                  }}
                </p>
              </a-col>
              <a-col :xs="12" :lg="5" class="mt-4 mt-lg-0">
                <h3 class="type">Student(s)</h3>
                <p class="value">
                  {{
                    overviewReport.totalStudent
                      ? overviewReport.totalStudent
                      : 0
                  }}
                  Students
                </p>
              </a-col>
              <a-col :xs="8" :lg="4">
                <h3 class="type">KKM</h3>
                <p class="value">
                  {{
                    overviewReport.kkm.kbm_master
                      ? overviewReport.kkm.kbm_master.nilai
                      : "-"
                  }}
                </p>
              </a-col>
              <a-col :xs="8" :lg="5" class="mt-4 mt-lg-0">
                <h3 class="type">KD Knowledge</h3>
                <p class="value" v-if="kdKnowledge">
                  {{ kdKnowledge.total }} ({{ kdKnowledge.mid }}
                  mid)
                </p>
                <p class="value" v-else>-</p>
              </a-col>
              <a-col :xs="8" :lg="5" class="mt-4 mt-lg-0">
                <h3 class="type">KD Skill</h3>
                <p class="value" v-if="kdSkill">
                  {{ kdSkill.total }} ({{ kdSkill.mid }}
                  mid)
                </p>
                <p class="value" v-else>-</p>
              </a-col>
            </a-row>
          </div>
          <div class="divider">
            <a-divider orientation="left">
              <h3>Report by Classes</h3>
            </a-divider>
          </div>
          <a-empty
            class="my-4"
            v-if="!reportMapelIsExist"
            :image-style="{ height: '100px' }"
          >
            <div slot="description">
              <span class="font-size-18" style="color: #0000009c">No Data</span>
              <br />
              <div style="color: #7474747a">
                It seems you are just getting started to fill report grading on
                level
                {{
                  overviewReport.level
                    ? `${overviewReport.level.nama}`
                    : "this level"
                }}.
              </div>
              <a-button
                :loading="loadingIntitalize"
                @click.prevent="initializeReportMapel"
                type="primary"
                class="mt-3"
              >
                Start Initialize
              </a-button>
            </div>
          </a-empty>
          <div class="table-presences" v-else>
            <a-table
              class="table-scroll"
              :columns="columns"
              :data-source="dataTable"
              :pagination="{ pageSize: 30, hideOnSinglePage: true }"
              :loading="loadingTable"
              :scroll="listKd.length > 2 ? { x: 100 } : {}"
              bordered
            >
              <!-- <div slot="NoData">No Data</div> -->
              <div slot="kelas" slot-scope="text, record">
                <router-link
                  :to="{
                    name: 'Detail Reports Teacher',
                    params: { idMapel: currentMapel, idKelas: record.id },
                  }"
                >
                  {{ text }}
                </router-link>
              </div>
              <span slot="Score" slot-scope="text">
                <template v-if="!text && text !== 0">
                  <a-tag>
                    <a-icon type="minus" />
                  </a-tag>
                </template>
                <template v-else>
                  <div v-if="text > 70 && text <= 100">
                    <a-tag color="green">{{ text.toFixed(2) }}</a-tag>
                  </div>
                  <div v-else-if="text > 35 && text <= 70">
                    <a-tag color="orange">{{ text.toFixed(2) }}</a-tag>
                  </div>
                  <div v-else-if="text >= 0 && text <= 35">
                    <a-tag color="red">{{ text.toFixed(2) }}</a-tag>
                  </div>
                </template>
              </span>
            </a-table>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { Empty } from 'ant-design-vue'
const LoadingState = () => import('@/components/app/LoadingState')

const rawColumn = [
  {
    title: 'Class',
    dataIndex: 'kelas',
    key: 'kelas',
    width: 200,
    fixed: 'left',
    align: 'center',
    scopedSlots: { customRender: 'kelas' },
  },
  {
    title: 'Students',
    key: 'totalStudent',
    dataIndex: 'totalStudent',
    width: 150,
    align: 'center',
    fixed: 'left',
  },
  {
    title: 'PTS',
    width: 240,
    children: [
      {
        title: 'Knowledge',
        dataIndex: 'pts_knowledge',
        key: 'pts_knowledge',
        width: 120,
        align: 'center',
        scopedSlots: { customRender: 'Score' },
      },
      {
        title: 'Skill',
        dataIndex: 'pts_skill',
        key: 'pts_skill',
        width: 120,
        align: 'center',
        scopedSlots: { customRender: 'Score' },
      },
    ],
  },
  {
    title: 'PAS',
    width: 240,
    children: [
      {
        title: 'Knowledge',
        dataIndex: 'pas_knowledge',
        key: 'pas_knowledge',
        width: 120,
        align: 'center',
        scopedSlots: { customRender: 'Score' },
      },
      {
        title: 'Skill',
        dataIndex: 'pas_skill',
        key: 'pas_skill',
        width: 120,
        align: 'center',
        scopedSlots: { customRender: 'Score' },
      },
    ],
  },
]

export default {
  data() {
    return {
      current: [0],
      rawColumn,
      dataTable: [],
      loadingTable: false,
      overviewReport: {},
      modalManageKdIsOpen: false,
      modalConfirmManageKd: false,
      confirmLoading: false,
      kdAmount: {
        mid: 0,
        final: 0,
        total: 0,
      },
      menuSlots: [],
      currentLevel: null,
      currentMapel: null,
      loadingOverview: false,
      reportMapelIsExist: false,
      listKd: [],
      loadingIntitalize: false,
    }
  },
  components: {
    LoadingState,
    // EmptyState: () => import('@/components/app/EmptyState'),
  },
  methods: {
    changeKdNumber() {
      this.kdAmount.final = this.kdAmount.total - this.kdAmount.mid
    },
    handleOkManage() {
      this.modalConfirmManageKd = true
    },
    handleCancelManage() {
      this.fetchDataTable()
      this.modalManageKdIsOpen = false
    },
    handleOkConfirm() {
      this.$store.dispatch('report/UPDATE_KD_BY_LEVEL_AND_SUBJECT', {
        idMapel: this.overviewReport.idMapel,
        tingkat: this.overviewReport.tingkat,
        kdAmount: this.kdAmount,
      })
        .then(isDone => {
          if (isDone) {
            this.$notification.success({
              message: 'Your KD has been updated',
            })
          } else {
            this.$notification.error({
              message: 'Sorry.',
              description: 'There must be some error in the server. Please try again.',
            })
          }
          this.fetchDataTable()
          this.modalConfirmManageKd = false
          this.modalManageKdIsOpen = false
        })
    },
    openManageKd() {
      this.modalManageKdIsOpen = true
      this.kdAmount.total = this.overviewReport.totalKd
      this.kdAmount.mid = this.overviewReport.totalKdMid
      this.kdAmount.final = this.kdAmount.total - this.kdAmount.mid
    },
    toDetailReport(idMapel, idKelas) {
      this.$router.push({ name: 'Detail Reports Teacher', params: { idMapel, idKelas } })
    },
    showOverview(idMapel, idLevel) {
      this.loadingOverview = true
      this.currentLevel = idLevel
      this.currentMapel = idMapel
      this.fetchDataOverview()
      this.fetchDataTable()
    },
    async fetchDataTable() {
      try {
        this.loadingTable = true
        const { data, listKd, reportMapelIsExist } = await this.$store.dispatch('report/FETCH_REPORT_MAPEL_BY_LEVEL', { idMapel: this.currentMapel, idLevel: this.currentLevel })
        this.dataTable = data.map(kelas => {
          return {
            ...kelas,
            key: kelas.id,
            kelas: `${kelas.level.nama}-${kelas.simbol}`,
            totalStudent: kelas.kelas_tahun_ajarans.length,
          }
        })
        this.listKd = listKd
        this.reportMapelIsExist = reportMapelIsExist
        this.loadingOverview = false
        this.loadingTable = false
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async fetchDataOverview() {
      try {
        this.loadingTable = true
        this.overviewReport = await this.$store.dispatch('report/FETCH_OVERVIEW_REPORT_MAPEL_BY_LEVEL', { idMapel: this.currentMapel, idLevel: this.currentLevel })
        this.loadingOverview = false
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async initializeReportMapel() {
      try {
        this.loadingIntitalize = true
        const { currentLevel: idLevel, currentMapel: idMapel } = this
        await this.$store.dispatch('report/CREATE_REPORT_MAPEL_BY_SUBJECT_AND_LEVEL', { idMapel, idLevel })
        this.loadingIntitalize = false
        this.$notification.success({
          message: 'Success',
        })
        this.fetchDataTable()
      } catch (err) {
        console.log(err)
        this.loadingIntitalize = false
        this.$notification.error({
          message: 'Sorry.',
          description: 'There must be some error in the server. Please try again or contact admin.',
        })
        this.fetchDataTable()
        return new Promise((resolve, reject) => reject(err))
      }
    },
  },
  async created() {
    try {
      this.menuSlots = await this.$store.dispatch('report/FETCH_MENU_REPORT_TEACHER')
      this.loadingTable = true
      this.loadingOverview = true
      this.$store.commit('SET_STATE', {
        isLoading: false,
      }, { root: true })
      if (this.menuSlots[0]) {
        this.loadingTable = false
        this.currentLevel = this.menuSlots[0]['kela.level.id']
        this.currentMapel = this.menuSlots[0].id_mata_pelajaran
        this.fetchDataOverview()
        this.fetchDataTable()
      } else {
        this.loadingTable = true
        this.loadingOverview = true
        this.$store.commit('SET_STATE', {
          isLoading: false,
        }, { root: true })
      }
    } catch (err) {
      console.log(err)
    }
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading
    },
    user() {
      return this.$store.state.user.user
    },
    columns() {
      const columns = []
      // console.log(this.selectedMapel)
      this.rawColumn.forEach((col, i) => {
        if (['kelas', 'totalStudent'].includes(col.key) && this.listKd.length <= 2) {
          columns.push({
            ...col,
            fixed: null,
          })
        } else {
          columns.push(col)
        }
        if (i === 1) {
          if (!this.listKd.length) {
            columns.push({
              title: 'KD',
              width: 200,
              align: 'center',
              customRender: (col, row, index) => {
                if (index === 0) {
                  return {
                    children: (
                      <a-empty image={Empty.PRESENTED_IMAGE_SIMPLE}>
                        <div slot="description" class="value">
                          <span class="font-size-18" style="color: #0000009C">No KD</span>
                          <div class="font-size-16">
                            <span class="text-primary" onClick={e => {
                              e.preventDefault()
                              this.$store.commit('menu/SET_STATE', {
                                currentMenu: ['Planning Teacher'],
                              })
                              this.$router.push({ name: 'Planning Teacher' })
                            }}>Go to 'Planning Menu'</span> to manage Your KD.
                          </div>
                        </div>
                      </a-empty>
                    ),
                    attrs: {
                      rowSpan: this.dataTable.length,
                    },
                  }
                } else {
                  return {
                    attrs: {
                      rowSpan: 0,
                    },
                  }
                }
              },
              // scopedSlots: { customRender: 'NoData' },
            })
          } else {
            this.listKd.forEach(colKd => {
              const children = [
                {
                  title: 'Knowledge',
                  dataIndex: 'knowledge' + colKd.no,
                  key: 'knowledge' + colKd.no,
                  width: 120,
                  align: 'center',
                  scopedSlots: { customRender: 'Score' },
                },
                {
                  title: 'Skill',
                  dataIndex: 'skill' + colKd.no,
                  key: 'skill' + colKd.no,
                  width: 120,
                  align: 'center',
                  scopedSlots: { customRender: 'Score' },
                },
              ]
              columns.push({
                title: 'KD-' + colKd.no,
                width: 200,
                align: 'center',
                children,
                // scopedSlots: { customRender: 'NoData' },
              })
            })
          }
        }
      })

      return columns
    },
    kdSkill() {
      if (this.overviewReport.kdSkill?.length) {
        const mid = this.overviewReport.kdSkill.filter(kd => kd.is_mid_report)
        return {
          mid: mid.length,
          total: this.overviewReport.kdSkill.length,
        }
      }
      return null
    },
    kdKnowledge() {
      if (this.overviewReport.kdKnowledge?.length) {
        const mid = this.overviewReport.kdKnowledge.filter(kd => kd.is_mid_report)
        return {
          mid: mid.length,
          total: this.overviewReport.kdKnowledge.length,
        }
      }
      return null
    },
  },
  watch: {
    'kdAmount.mid'(newVal, oldVal) {
      if (newVal === '') {
        this.kdAmount.mid = 0
      }

      this.kdAmount.mid = Math.floor(this.kdAmount.mid)
    },
    'kdAmount.total'(newVal, oldVal) {
      if (newVal === '') {
        this.kdAmount.total = 0
      }
      this.kdAmount.total = Math.floor(this.kdAmount.total)
    },
  },
}
</script>

<style lang="scss">
@import "@/components/mixins.scss";
.reportCenter {
  .card-slots {
    padding: 20px 20px;
    margin-top: 60px;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #0419301a;
  }

  .detail-slot {
    padding: 40px 20px;
  }

  .detail-slot .type {
    font-family: "Montserrat", sans-serif;
    font-size: 17px;
  }

  .detail-slot .value {
    padding-top: 5px;
    font-family: "Mukta", "sans-serif";
    font-size: 16px;
  }

  // .detail-slot .value span {
  //   color: #1b90ff;
  //   cursor: pointer;
  // }

  .value span {
    color: $primary;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  .progress-card {
    padding: 10px 20px;
  }

  .table-presences {
    padding: 10px 20px 20px 20px;
  }

  .table-scroll {
    .ant-spin-nested-loading .ant-spin-container .ant-table {
      overflow-x: auto;
    }
  }
}
</style>
